class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    if (process.browser) {
      var w = window;

      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
      }
      // var d = window.document;
      var ch = function() {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      } else if (window.attachEvent) {
        window.attachEvent('onload', l);
      } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
      }
    }
  }

  boot(profile) {
    if (process.browser) {
      const settings = {
        pluginKey: '1fd9ee3c-648e-4a82-8ca5-9012795a75c0',
      }

      if (profile) {
        settings.profile = {
          name: profile.name,
          mobileNumber: profile.phone,
        }
      }

      window.ChannelIO('boot', settings)
    }
  }

  shutdown() {
    if (process.browser) {
      window.ChannelIO('shutdown');
    }
  }
}

export default new ChannelService();
